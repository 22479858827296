import React from "react";
import Cookies from "js-cookie";
import { AppBar, Toolbar, IconButton, MenuItem, Menu } from "@mui/material";
import { useSelector } from "react-redux";

import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useNavigate } from "react-router-dom";

const Nav = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.userLogin);

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("userInfo");
    window.location.reload();
  };

  return (
    <nav>
      <AppBar position="static" style={{ zIndex: "1021 !important" }}>
        <Toolbar>
          {loginInfo.stats == 1 ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={() => navigate("/qr-code-scanner")}
            >
              <QrCodeScannerIcon />
            </IconButton>
          )}
          <div className="app-bar-content" onClick={() => navigate("/")}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.svg`}
              width={100}
              height={50}
              style={{ cursor: "pointer" }}
            />
          </div>
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>تسجيل الخروج</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Nav;
