import React from "react";
import { Link } from "react-router-dom";
import { FaTiktok } from "react-icons/fa6";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a href="https://www.facebook.com/ranadeliv?mibextid=ZbWKwL">
        <FacebookIcon
          className="icon"
          style={{ fontSize: "2rem", color: "#1877F2" }}
        />
      </a>
      <a href="https://www.instagram.com/rana_delivery?igsh=MXVyYnF0Zno2M2tqNA==">
        <InstagramIcon
          className="icon"
          style={{ fontSize: "2rem", color: "#E1306C" }}
        />
      </a>
      <a href="">
        <FaTiktok
          className="icon"
          style={{ fontSize: "2rem", color: "#102002" }}
        />
      </a>
      <a href="">
        <LinkedInIcon
          className="icon"
          style={{ fontSize: "2rem", color: "#0A66C2" }}
        />
      </a>
    </div>
  );
};

export default SocialLinks;
