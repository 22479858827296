// src/theme.js (or any suitable filename)
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  direction: "rtl",

  palette: {
    primary: {
      main: "#045b55",
    },
  },

  typography: {
    fontFamily: "Changa", // Set your custom font-family here
    fontSize: 14,
  },

  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          noRowsLabel: "لا توجد بيانات", // Custom "No rows" text in Arabic
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
        }),
      },
    },
  },
});

export default theme;
